/* @import '../node_modules/font-awesome/css/font-awesome.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --aa-base-z-index: 10;
  --aa-base-color: #000;
}

.MuiDialog-paperFullWidth {
  width: calc(100% - 64px);
  max-width: calc(100% - 64px) !important;
  height: calc(100% - 64px);
}

.MuiListSubheader-root {
  line-height: normal !important;
  margin-top: 8px;
}
