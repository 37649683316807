// https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-theme-classic/
:root {
  --aa-base-z-index: 10;
  --aa-primary-color-rgb: 113, 185, 161;
  --aa-text-color-rgb: 113, 185, 161;
  --aa-panel-border-color-rgb: 0, 0, 0;
  --aa-input-border-color-rgb: 255, 255, 255;
  // --aa-muted-color-rgb: 0, 0, 0;
  // --aa-input-border-color-rgb: 0, 0, 0;
  --aa-background-color-rgb: 255, 255, 255;
  // --aa-panel-shadow: 60px -16px teal;
  // --aa-selected-color-rgb: 0, 0, 0;
  --aa-font-family: "Montserrat", sans-serif;
  --aa-selected-color-rgb: 113, 185, 161;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, OpenSans;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
  //   "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: rgb(244, 244, 249);
  color: rgb(65, 65, 65);
}

a {
  color: var(--aa-primary-color);
  text-decoration: none;
}

.header {
  background: rgb(252 252 255 / 92%);
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.05), 0 1px 3px 0 rgba(35, 38, 59, 0.15);
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.header-wrapper {
  align-items: center;
  display: grid;
}

.header-nav {
  font-weight: 500;
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 52px;
  width: 100%;
}

.container {
  margin-top: 3.5rem;
  padding: 1.5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 3fr;
}

/* Autocomplete */

.aa-searchbox {
  width: 600px;
}
.aa-Form {
  border-radius: 32px;
}

.aa-Panel {
  position: fixed;
}

.aa-Item[aria-selected=true] {
  background-color: white;
  box-shadow: 0px 0px 12px rgba(55, 66, 74, 0.4);

}

.aa-ItemActionButton {
  display: flex;
  justify-content: right;
  align-items: center;
  background: none;
  color: rgba(255, 255, 255, 0);
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding: 0;
}

.aa-ItemContent {
  display: flex;
  justify-content: start;
  width: 100%;
}

.aa-Panel {
  z-index: 10;
  border-radius: 24px;
  .aa-Panel--scrollable {
    padding-left:0;
    padding-right:0;
    padding:0;
  }
}

.aa-ItemIcon {
  box-shadow: none;
  background-color: none;
  margin-left: 10px;
  width: 30px !important;
  height: 30px !important;
  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.aa-ActiveOnly {
  box-shadow: #E3E1DE;
}

.aa-no-results {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
  opacity: 0.8;
}

.aa-List {
  .aa-Item:not(:last-child) {
    border-bottom: 1px solid #E3E1DE;
  }
}

.aa-RecentSearch {
  color: var(--Argent, #A6ACAF);
  .aa-ItemIcon {
    width: 25px !important;
    height: 25px !important;
    color: var(--Argent, #A6ACAF);
    svg {
      width: 25px !important;
      height: 25px !important;
      color: var(--Argent, #A6ACAF);
    }
  }
}

/* InstantSearch */

.ais-Hits-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  margin:0;
  padding:0;
}

.ais-Hits-item {
  padding: 1rem !important;
}

.ais-Pagination {
  display: flex;
  justify-content: center;
}

.ais-Pagination-list {
  list-style: none;
  display:flex;
}

.ais-Pagination-item {
  margin: 0 0.3rem !important;
  // background-color: var(--turquoise-30, rgba(113, 185, 161, 0.30));
  // color: #5F6971;
  background-color: white;
  color: #71B9A1;
  font-size: 12px;
  border-radius: 4px;
  padding: 0.4rem !important;
}

/* Search box */
.ais-SearchBox {
  max-width: 1200px;
  padding: 0 52px;
  width: 700px;
  font-family: Montserrat;
  transform: translateY(-50px);
} 

.ais-SearchBox-resetIcon path {
  fill: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
}
.ais-SearchBox-submitIcon path {
  fill: #71B9A1;
}

.ais-SearchBox-input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #71B9A180
}

.ais-SearchBox-form {
  position: relative;
}

.ais-SearchBox-input {
  color: #71B9A1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: none;
  border-radius: 32px;
  font-family: inherit;
  height: 46px;
  outline: none;
  padding-left: 56px;
  width: 100%;
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-submit {
  border-radius: 32px;
  background-color: white;
  border: none;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;

}

.ais-SearchBox-reset[hidden],
.ais-SearchBox-loadingIndicator[hidden] {
  display: none;
}

.ais-SearchBox-submit {
  left: 0;
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  right: 0;
}

.ais-SearchBox-resetIcon {
  height: 10px;
  width: 10px;
}

.ais-SearchBox-submitIcon {
  height: 16px;
  width: 16px;
}

.ais-SearchBox-input::-webkit-input-placeholder {
  color: rgba(var(--aa-muted-color-rgb), var(--aa-muted-color-alpha));
}