.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.Loading-progress {
  height: 5px;
  width: 100%;
  border-radius: 0;
  background: linear-gradient(90deg, #044742 0%, #71b9a1 15%, #71b9a1 30%, #F9F6ED 50%, #71b9a1 65%, #71b9a1 80%, #044742 100%);
  background-size: 200%;
  animation: moveGradient 5s linear infinite;
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
}