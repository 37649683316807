html {
    background-color:#ebe9e4;
  }

  .container {
      display: flex;
      /* height:100vh; */
      height:70%;
      justify-content: center;
      align-items: center;
  }

  .dots {
     width: 35.8px;
     height: 35.8px;
     --c: radial-gradient(circle closest-side,#71b9a1 90%,#0000);
     background: var(--c) 0    0,
          var(--c) 0    100%,
          var(--c) 100% 100%;
     background-size: 13.4px 13.4px;
     background-repeat: no-repeat;
     animation: dots-q2rla8 1s infinite linear;
  }

  @keyframes dots-q2rla8 {
     25% {
        background-position: 100% 0   ,0 100%,100% 100%;
     }

     50% {
        background-position: 100% 0   ,0 0   ,100% 100%;
     }

     75% {
        background-position: 100% 0   ,0 0   ,0    100%;
     }

     100% {
        background-position: 100% 100%,0 0   ,0    100%;
     }
  }